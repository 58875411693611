<!-- 百科列表 -->
<template>
	<CommonPage>
		<div class="index all-list">
			<Wrapper color="#121212">
				<div class="top">
					<img
						src="@/assets/all.png"
						alt=""
						class="all-icon" />
					<h1>All Content</h1>
				</div>
			</Wrapper>
			<Wrapper color="#ffffff">
				<div class="b">
					<div class="son">
						<div class="hs">
							<div
								@click="$router.push({name: 'detail', params: { id: item?.id, title: item.title.toLocaleLowerCase().replaceAll(' ', '-') }})"
								v-for="item in list"
								:key="item?.title"
								class="itemBlock pointer"
								:style="{ background: `url(${item?.thumbnailImgUrl}) center center / cover no-repeat, linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0))` }">
								<div class="e"></div>
								<div class="num">{{ item?.category }}</div>
								<div class="title">
									{{ item?.title }}
								</div>
							</div>
							<Ad
								:ads="adsensConfig.ad6"
								:posi="{
									pc: {
										rowAt: 3,
										colNum: 3,
									},
									m: 3,
								}"></Ad>
							<Ad
								:ads="adsensConfig.ad7"
								:posi="{
									m: 7,
								}"></Ad>
						</div>
						<div
							v-show="isPC"
							@click="clkNext"
							:style="sty"
							class="no-more">
							{{ hasNext ? 'Load More' : 'No More' }}
						</div>
					</div>
				</div>
			</Wrapper>
		</div>
	</CommonPage>
</template>
<script>
import '@/css/index.scss'
import { getList } from '../api'
export default {
	metaInfo() {
		return {
			title: this.title,
			meta: [
				{
					name: 'description',
					content: this.description,
				},
				{
					name: 'title',
					content: this.title,
				},
				{
					property: 'og:title',
					content: this.title,
				},
				{
					property: 'og:description',
					content: this.description,
				},
			],
		}
	},
	data() {
		return {
			posi: '',
			quoteEng: '',
			pageNum: 1,
			list: [],
			hasNext: true,
		}
	},
	computed: {
		sty() {
			return {
				background: this.hasNext ? '#f6cf5f' : '#fdf5dd',
				color: this.hasNext ? 'black' : '#FECD45',
			}
		},
		title() {
			return `All | ${this.domainConfig['title']}`
		},
		description() {
			return this.domainConfig.description
		},
	},
	async activated() {
		// await this.loadAdSenseScript() // 重新加载广告脚本
	},
	async beforeMount() {
		if (this.isPC) {
			this.getList(this.pageNum)
		} else {
			this.getList(this.pageNum, 999)
		}
	},
	methods: {
		clkNext () {
			this.pageNum++
			this.getList(this.pageNum)
		},
		async getList(pageNum, pageSize = 30) {
			if (!this.hasNext) {
				return
			}
			const res = await getList({
				type: this.domainConfig['type'],
				pageNum,
				pageSize,
			})
			this.hasNext = res.hasNext

			console.log('🚀🚀🚀 res: ', res)
			this.list.push(...res.popularizations)
		},
	},
}
</script>