var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CommonPage',[_c('div',{staticClass:"index"},[_c('Wrapper',{attrs:{"color":"#121212"}},[_c('div',{staticClass:"top"},[_c('h1',[_vm._v(_vm._s(_vm.domainConfig.title))]),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(`${_vm.month} | ${_vm.day}`)+" ")])])]),_c(_vm.contentComponent,{tag:"component",attrs:{"curOne":_vm.curOne}}),_c('Wrapper',{attrs:{"color":"#ffffff"}},[_c('div',{staticClass:"b"},[_c('div',{staticClass:"son"},[_c('Ad',{attrs:{"ads":_vm.adsensConfig.ad1}}),_c('div',{staticClass:"text"},[_vm._v("Related Content")]),_c('div',{staticClass:"hs"},[_vm._l((_vm.list),function(item){return _c('div',{key:item?.id,staticClass:"itemBlock pointer",style:({ background: `url(${item?.thumbnailImgUrl}) center center / cover no-repeat, linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0))` }),on:{"click":function($event){_vm.$router.push({ name: 'detail', params: { id: item?.id, title: item.title.toLocaleLowerCase().replaceAll(' ', '-') } })}}},[_c('div',{staticClass:"e"}),_c('div',{staticClass:"num"},[_vm._v(_vm._s(item?.category))]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item?.title)+" ")])])}),_c('Ad',{attrs:{"ads":_vm.adsensConfig.ad2,"posi":{
								pc: {
									colNum: 3,
									rowAt: 4
								},
								m: 3
							}}}),_c('Ad',{attrs:{"ads":_vm.adsensConfig.ad3,"posi":{
								m: 7
							}}})],2),_c('div',{staticClass:"view-all-btn",on:{"click":function($event){return _vm.$router.push({ name: 'all-list' })}}},[_vm._v(" View All ")])],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }