var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CommonPage',[_c('div',{staticClass:"index all-list"},[_c('Wrapper',{attrs:{"color":"#121212"}},[_c('div',{staticClass:"top"},[_c('img',{staticClass:"all-icon",attrs:{"src":require("@/assets/all.png"),"alt":""}}),_c('h1',[_vm._v("All Content")])])]),_c('Wrapper',{attrs:{"color":"#ffffff"}},[_c('div',{staticClass:"b"},[_c('div',{staticClass:"son"},[_c('div',{staticClass:"hs"},[_vm._l((_vm.list),function(item){return _c('div',{key:item?.title,staticClass:"itemBlock pointer",style:({ background: `url(${item?.thumbnailImgUrl}) center center / cover no-repeat, linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0))` }),on:{"click":function($event){_vm.$router.push({name: 'detail', params: { id: item?.id, title: item.title.toLocaleLowerCase().replaceAll(' ', '-') }})}}},[_c('div',{staticClass:"e"}),_c('div',{staticClass:"num"},[_vm._v(_vm._s(item?.category))]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item?.title)+" ")])])}),_c('Ad',{attrs:{"ads":_vm.adsensConfig.ad6,"posi":{
								pc: {
									rowAt: 3,
									colNum: 3,
								},
								m: 3,
							}}}),_c('Ad',{attrs:{"ads":_vm.adsensConfig.ad7,"posi":{
								m: 7,
							}}})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPC),expression:"isPC"}],staticClass:"no-more",style:(_vm.sty),on:{"click":_vm.clkNext}},[_vm._v(" "+_vm._s(_vm.hasNext ? 'Load More' : 'No More')+" ")])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }